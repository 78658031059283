
.Leadership {
    flex-wrap: wrap;
    display: flex;

    justify-content: center;
}

.Leadership img {
    border-radius: 90px;

}
.Chairs {
    flex-wrap: wrap;
    display: flex;

    justify-content: center;
}

.Chairs img {
    border-radius: 90px;

}