.Membership {
    max-width: 100vw;
}

.JoinIntro {
    background-color: var(--secondary);
    color: #fff;

    text-align: center;
    padding: 32px 18px;

    font-size: 46px;
    font-weight: 600;
}

.signup {
    color: #fff;
}
#list {
    margin: 15px;
}

#joinLink {
    font-weight: 700;
    font-size: 20px;
    color: var(--tertiary);
    text-decoration: none;
}
#subtext {
    font-size: 18px;
    font-weight: 400;
}

#modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400;
    background-color: var(--secondary);
    box-shadow: 24;
    padding: 19px;    
}

#button {
    color: var(--tertiary);
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

#howtotitle {
    font-size: 23px;
    font-weight: 500;

    color: #fff;
}

#link {
    color: var(--tertiary);
}