img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 8vw;
    max-height: 8vw;

  }

.Card {

    width: fit-content;
    height: fit-content;

    padding: 9px;
    margin: 9px;

    color: var(--title);
}

.CardText {
    margin-top: 18px;

    text-align: left;

}

#image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 8vw;
    max-height: 8vw;

}


#name {
    font-weight: 700;
    margin: 0;
}

#position {
    opacity: 70%;
    margin: 0;
}