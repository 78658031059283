.Gallery {
    width: 100vw;
    display: block;

}

.GalleryIntro {
    background-color: var(--secondary);
    color: #fff;

    width: 100vw;
    text-align: center;
    padding: 32px 18px;
}

#post {
    
}