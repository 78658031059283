@media only screen and (max-width: 800px) {

}
input[type=text], input[type=email],textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    background-color: var(--primary);
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    
    box-sizing: border-box;
}
input[type=text], input[type=email],textarea:focus {
    outline-width: 0;
}
.Home {
    color: var(--title);
    max-width: 100vw;
}

.Intro {
    margin-bottom: 3%;

}

.IntroSection {

    background-image: url('../Img/group.png');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment:scroll;  
    background-size: cover;

    height: 90vh;

    text-align: center;
    padding-top: 45vh;

    user-select: none;
}

.Image {
    background-image: url('../Img/ContactImg.jpg');
}

.Contact {
    display: grid;
    grid-template-columns: 50% 50%;
}

.contactForm {
    float: right;
    padding: 15%;
    background-color: var(--primary);
    color: #fff;
}

.Form{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    margin-top: 36px;
}

#intro {
    opacity: 1;

    color: #fff;

    font-size: 6vh;
    font-weight: 600; 
    
    text-shadow: 0px 0px 10px #181818;
}

#fname {
    grid-area: 1 / 1 / span 1 / span 1;
}

#lname {
    grid-area: 1 / 2 / span 1 / span 1;
}

#email {
    grid-area: 2 / 1 / span 1 / span 1;
}

#subject {
    grid-area: 2 / 2 / span 1 / span 1;
}

#message {
    grid-area: 3 / 1 / span 1 / span 2;
}

#submit {
    background-color:var(--secondary);
    color: #fff;

    cursor: pointer;

    margin-top: 18px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
    

    border: none;
    box-shadow: none;
    border-radius: 36px;
}